import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';

const initialState = {
    name: ""
}

function PermissionsForm(props) {
    const [details, setDetails] = useState(initialState)
    const { name } = details

    const state = useContext(GlobalState)
    const [, setAlert] = state.alert;

    const handleChange = e => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value })
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }


        try {
            const res = await axios.post(`${API_ENDPOINT}/permissions`, { ...details },
                { withCredentials: true, credentials: 'include' }
            )
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
        } catch (error) {
            // console.log(error)
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    return (
        <div className='container border py-3'>
            <div className='row'>
                <div className='col-lg-3'>
                    <h6>Create new permissions</h6>
                </div>
                <div className='col-lg-9'>
                    <div className='bg-white p-3'>
                        <form onSubmit={handleSubmit}>

                            <div className='row mb-3'>
                                <div className='col-lg-12'>
                                    <label htmlFor='fullname' className='form-label'>Permission</label>
                                    <input type="text" className='form-control form-control-md' id="name" name='name'
                                        value={name} onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className='d-flex'>
                                <Link to="/user-management/permissions"><button className='btn-b me-2'>Cancel</button></Link>
                                <button type="submit" className='btn-c'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PermissionsForm;