import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/auth/Login';
import Studies from './pages/studies/Studies';
import Sites from './pages/sites/Sites';
import Staffs from './pages/staffs/Staffs';
import Profile from './pages/profile/Profile';
import StaffLists from './pages/staffs/StaffLists';
import StaffForm from './pages/staffs/Forms/StaffForm';
import StudyLists from './pages/studies/StudyLists';
import NewStudy from './pages/studies/NewStudy';
import SiteLists from './pages/sites/SiteLists';
import UserManagement from './pages/UserManagement/UserManagement';
import Users from './pages/UserManagement/Users';
// import Roles from './pages/UserManagement/Roles';
import Permissions from './pages/staffs/Permissions';
import Budgets from './pages/budget/Budgets';
import Overview from './pages/budget/Overview';
import StudiesBudget from './pages/budget/StudiesBudget';
import RolesForm from './pages/staffs/Forms/RolesForm';
import PermissionsForm from './pages/staffs/Forms/PermissionsForm';
import { useContext } from 'react';
import { GlobalState } from './GlobalState';
import SiteDetails from './pages/sites/SiteDetails';
import ProfileDetails from './pages/profile/ProfileDetails';
import ChangePassword from './pages/profile/ChangePassword';
import EditProfile from './pages/profile/Forms/EditProfile';
import FacilitiesDetails from './pages/sites/FacilitiesDetails';
import CapabilitiesDetails from './pages/sites/CapabilitiesDetails';
import TherapeuticAreasDetails from './pages/sites/TherapeuticAreasDetails';
import Depts from './pages/staffs/Depts';
import Designations from './pages/staffs/Designations';
import Alert from './utils/Alert';
import StudyInformation from './pages/studies/StudyInformation';
import Loader from './utils/Loader';
import StudyInterestInfo from './pages/studies/StudyInterestInfo';

function App() {
  const state = useContext(GlobalState)
  const [isLogged] = state.isLogged;
  const [loading] = state.loading;

  return (
    <Router>
      {loading && <Loader />}
      <Alert />

      <div className="App">
        <main className="App-body">
          <Routes>
            <Route path="/" element={isLogged ? <Dashboard /> : <Login />} />

            {/* AUTH ROUTES */}
            <Route path="/login" element={<Login />} />

            {/* STUDIES ROUTES */}
            <Route path="/studies" element={isLogged ? <Studies /> : <Login />} >
              <Route path="/studies" element={isLogged ? <StudyLists /> : <Login />} />
              <Route path="/studies/new-study" element={isLogged ? <NewStudy /> : <Login />} />
              <Route path="/studies/edit-study/:id" element={isLogged ? <NewStudy /> : <Login />} />
              <Route path="/studies/study-information" element={isLogged ? <StudyInformation /> : <Login />} />
              <Route path="/studies/study-information/:id" element={isLogged ? <StudyInterestInfo /> : <Login />} />
            </Route>

            {/* SITES ROUTES */}
            <Route path="/sites" element={isLogged ? <Sites /> : <Login />} >
              <Route path="/sites" element={isLogged ? <SiteLists /> : <Login />} />
              <Route path="/sites/:id" element={isLogged ? <SiteDetails /> : <Login />} />
              <Route path="/sites/facilities-settings" element={isLogged ? <FacilitiesDetails /> : <Login />} />
              <Route path="/sites/capabilities-settings" element={isLogged ? <CapabilitiesDetails /> : <Login />} />
              <Route path="/sites/therapeutic-areas-settings" element={isLogged ? <TherapeuticAreasDetails /> : <Login />} />
            </Route>

            {/* BUDGET ROUTES */}
            <Route path="/budgets" element={isLogged ? <Budgets /> : <Login />} >
              <Route path="/budgets" element={isLogged ? <Overview /> : <Login />} />
              <Route path='/budgets/studies-budget' element={isLogged ? <StudiesBudget /> : <Login />} />
            </Route>

            {/* STAFF ROUTES */}
            <Route path="/staffs" element={isLogged ? <Staffs /> : <Login />} >
              <Route path="/staffs" element={isLogged ? <StaffLists /> : <Login />} />
              <Route path='/staffs/add-staff' element={isLogged ? <StaffForm /> : <Login />} />
              <Route path='/staffs/edit-staff/:id' element={isLogged ? <StaffForm /> : <Login />} />
              <Route path='/staffs/departments' element={isLogged ? <Depts /> : <Login />} />
              <Route path='/staffs/designations' element={isLogged ? <Designations /> : <Login />} />
              <Route path='/staffs/roles/new-role' element={isLogged ? <RolesForm /> : <Login />} />
              <Route path='/staffs/permissions' element={isLogged ? <Permissions /> : <Login />} />
              <Route path='/staffs/permissions/new-permission' element={isLogged ? <PermissionsForm /> : <Login />} />
            </Route>

            {/* USER MANAGEMENT */}
            <Route path="/user-management" element={isLogged ? <UserManagement /> : <Login />} >
              <Route path="/user-management" element={isLogged ? <Users /> : <Login />} />
            </Route>

            {/* PROFILE ROUTES */}
            <Route path="/my-account" element={isLogged ? <Profile /> : <Login />} >
              <Route path="/my-account" element={isLogged ? <ProfileDetails /> : <Login />} />
              <Route path="/my-account/edit-profile/:id" element={isLogged ? <EditProfile /> : <Login />} />
              <Route path="/my-account/change-password" element={isLogged ? <ChangePassword /> : <Login />} />
            </Route>
          </Routes>
        </main>
      </div>

    </Router>
  );
}

export default App;
