import React from 'react';

function Header() {
    return (
        <div className='w-100 p-3' style={{ background: '#fff', color: "#333" }}>
            <h5 className=''>
                Phoenix TMG - STAFF PORTAL
            </h5>
        </div>
    );
}

export default Header;