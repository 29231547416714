import { useState, useEffect } from 'react';
import axios from 'axios'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


export default function StudyInterestsAPI(staffId) {
    const [studyInterestsData, setStudyInterestsData] = useState([])
    const [callback, setCallback] = useState(false)
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [result, setResult] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (staffId) {
            const getStudies = async () => {
                const res = await axios.get(`${API_ENDPOINT}/study-interests/staff/${staffId}`)
                setStudyInterestsData(res.data.items)
                setResult(res.data.result)
                setTotal(res.data.total)
            }
            getStudies()
        }
    }, [callback, staffId])


    return {
        studyInterestsData: [studyInterestsData, setStudyInterestsData],
        callback: [callback, setCallback],
        search: [search, setSearch],
        page: [page, setPage],
        result: [result, setResult],
        total: [total, setTotal]
    }
}