import React, { useContext, useState } from 'react';
import { GlobalState } from '../../GlobalState';
import axios from 'axios';

import { PiTrademarkRegisteredLight } from 'react-icons/pi';
// import Alert from '../../utils/Alert';

const initialState = {
    password: "",
    cf_password: ""
}

function ChangePassword(props) {
    const [passwords, setPasswords] = useState(initialState);
    const { password, cf_password } = passwords;

    const [typePass, setTypePass] = useState(false)
    const [typeCfPass, setTypeCfPass] = useState(false)

    const state = useContext(GlobalState);
    const [staffData] = state.staffData
    const [, setAlert] = state.alert;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswords({ ...passwords, [name]: value })
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    const ChangeAccPassword = async (e) => {
        e.preventDefault()

        if (!password || !cf_password) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }
        if (password !== cf_password) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'Passwords do not match',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }
        try {
            const res = await axios.post(`${API_ENDPOINT}/staffs/change-password`, { ...passwords, staff_id: staffData._id })
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setPasswords(initialState)
        } catch (error) {
            // console.log(error)
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }
    return (
        <>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className=''>
                        <h6>Change Account Password</h6>

                        <ul>
                            <li>Enter a new password that is at least 8 characters long.</li>
                            <li>Use a combination of uppercase and lowercase letters, numbers, and special characters to enhance security.</li>
                            <li>Avoid using easily guessable information such as birthdays or common words</li>
                            <li>Re-enter the new password to ensure it matches the one you entered initially.</li>
                            <li>Click the "Change Password" button to apply the changes.</li>
                        </ul>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='p-3 bg-white'>

                        <form onSubmit={ChangeAccPassword}>
                            <div className="mb-3">
                                <div className="pass">
                                    <label htmlFor="password" className="form-label fw-bold">Password</label>
                                    <input type={typePass ? "text" : "password"} id="password" name="password" className="form-control form-control-sm"
                                        value={password} onChange={handleChange}
                                    />

                                    <small onClick={() => setTypePass(!typePass)} >
                                        {typePass ? 'Hide' : 'Show'}
                                    </small>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="pass">
                                    <label htmlFor="cf_password" className="form-label fw-bold">Confirm Password</label>
                                    <input type={typeCfPass ? "text" : "password"} id="cf_password" name="cf_password" className="form-control form-control-sm"
                                        value={cf_password} onChange={handleChange} />

                                    <small onClick={() => setTypeCfPass(!typeCfPass)} >
                                        {typeCfPass ? 'Hide' : 'Show'}
                                    </small>
                                </div>
                            </div>

                            <div className="d-flex w-100 pt-3 pb-2">
                                <button className="btn-a" type="submit" style={{ width: '100%' }}>
                                    Change Password
                                    <span className="ms-2"><PiTrademarkRegisteredLight /></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;