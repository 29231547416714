import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';

const initialState = {
    firstname: "",
    othernames: "",
    lastname: "",
    email: "",
    password: "",
    department: "",
    role: ""
}

function StaffForm(props) {
    const [details, setDetails] = useState(initialState)
    const { firstname, othernames, lastname, email, password, department } = details

    const state = useContext(GlobalState)
    const [departmentsData] = state.departmentsApi.departmentsData;
    const [, setAlert] = state.alert;
    const [typePass, setTypePass] = useState(false);
    const [callback, setCallback] = state.staffsApi.callback;

    const param = useParams()

    const [onEdit, setOnEdit] = useState(false)
    
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    // useEffect(() => {
    //     if (param.id) {
    //         setOnEdit(true)
    //         const res = axios.get(`${API_ENDPOINT}/staffs/${param.id}`)
    //         setDetails(res.data)
    //     } else {
    //         setOnEdit(false)
    //         setDetails(initialState)
    //         // setImages(false)
    //     }
    // }, [param.id, details, API_ENDPOINT])

    const handleChange = e => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!firstname || !lastname || !email || !password) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }


        try {
            if (onEdit) {
                const res = await axios.put(`${API_ENDPOINT}/staffs/${details._id}`, { ...details },
                    { withCredentials: true, credentials: 'include' }
                )
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: res.data.msg,
                    successMsg: '',
                });
            } else {
                const res = await axios.post(`${API_ENDPOINT}/staffs`, { ...details },
                    { withCredentials: true, credentials: 'include' }
                )
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: res.data.msg,
                    successMsg: '',
                });
            }
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setDetails(initialState)
            setCallback(!callback)
        } catch (error) {
            // console.log(error)
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }


    return (
        <>
            <div className='container border py-3'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <h6>Add a staff</h6>
                    </div>
                    <div className='col-lg-9'>
                        <div className='bg-white p-3'>
                            <form onSubmit={handleSubmit}>
                                <div className='row mb-3'>
                                    <div className='col-lg-6'>
                                        <label htmlFor='fullname' className='form-label'>First name</label>
                                        <input type="text" className='form-control form-control-md' id="firstname" name='firstname'
                                            value={firstname} onChange={handleChange}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label htmlFor='fullname' className='form-label'>Other names</label>
                                        <input type="text" className='form-control form-control-md' id="othernames" name='othernames'
                                            value={othernames} onChange={handleChange}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label htmlFor='fullname' className='form-label'>Last name</label>
                                        <input type="text" className='form-control form-control-md' id="lastname" name='lastname'
                                            value={lastname} onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <div className='col-lg-12'>
                                        <label htmlFor='email' className='form-label'>Email</label>
                                        <input type="text" className='form-control form-control-md' id="email" name='email'
                                            value={email} onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <div className='col-lg-12'>
                                        <div className='pass'>
                                            <label htmlFor='password' className='form-label' >Password:</label>
                                            <input type={typePass ? "text" : "password"} id="password" name="password" className="form-control form-control-md"
                                                value={password} onChange={handleChange}
                                            />

                                            <small onClick={() => setTypePass(!typePass)} >
                                                {typePass ? 'Hide' : 'Show'}
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <div className='col-lg-12'>
                                        <label htmlFor='department' className='form-label'>Department</label>
                                        {/* <input type="text" className='form-control form-control-md' id="department" name='department'
                                            value={department} onChange={handleChange}
                                        /> */}
                                        <select className="form-select form-select-md" aria-label="Default select example"
                                            // value={department}
                                            onChange={handleChange}
                                            name='department'
                                            value={department}
                                        >
                                            <option value="">Please select a department</option>
                                            {
                                                departmentsData.map((e) => (
                                                    <option key={e._id} value={e._id} > {e.name} </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                {/* <div className='row mb-3'>
                                    <div className='col-lg-12'>
                                        <label htmlFor='role' className='form-label'>Role</label>
                                        <input type="text" className='form-control form-control-md' id="department" name='department'
                                            value={department} onChange={handleChange}
                                        />
                                    </div>
                                </div> */}

                                <div className='d-flex'>
                                    <Link to="/staffs"><button className='btn-b me-2'>Cancel</button></Link>
                                    <button type="submit" className='btn-c'>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StaffForm;