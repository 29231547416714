import React from 'react';

function Overview(props) {
    return (
        <div>
            <h6>Budget Overview Page</h6>
        </div>
    );
}

export default Overview;