import { useState, useEffect } from 'react';
import axios from 'axios'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


export default function StudyStatusAPI() {
    const [studyStatusData, setStudyStatusData] = useState([])
    const [callback, setCallback] = useState(false)
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [result, setResult] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        const getRoles = async () => {
            const res = await axios.get(`${API_ENDPOINT}/study-status`)
            setStudyStatusData(res.data.items)
            setResult(res.data.result)
            setTotal(res.data.total)
        }
        getRoles()
    }, [callback])


    return {
        studyStatusData: [studyStatusData, setStudyStatusData],
        callback: [callback, setCallback],
        search: [search, setSearch],
        page: [page, setPage],
        result: [result, setResult],
        total: [total, setTotal]
    }
}