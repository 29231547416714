import React, { useContext, useEffect, useState } from 'react';
import CSidebar from '../components/Nav/CSidebar';
import Header from '../components/Header/Header';
import { GlobalState } from '../GlobalState';
import axios from 'axios';

import { MdLibraryBooks } from "react-icons/md";
import { MdOutlineCollectionsBookmark } from "react-icons/md";
import { FaSitemap } from "react-icons/fa";

function Dashboard() {
    const state = useContext(GlobalState);
    const [staffData] = state.staffData;


    // eslint-disable-next-line no-unused-vars
    const [staffStudyCount, setStaffStudyCount] = useState(null);
    const [totalStudyCount, setTotalStudyCount] = useState(null);
    const [activeStudiesCount, setActiveStudiesCount] = useState(null);

    const [siteCount, setSiteCount] = useState(null);
    const [sitesList, setSitesList] = useState([]);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch staff study count
                const staffCountResponse = await axios.get(`${API_ENDPOINT}/studies/count/staff/${staffData._id}`);
                setStaffStudyCount(staffCountResponse.data.item);

                // Fetch total study count
                const totalStudyCountResponse = await axios.get(`${API_ENDPOINT}/studies-count`);
                setTotalStudyCount(totalStudyCountResponse.data.item);

                // Fetch active study count
                const activeStudiesRes = await axios.get(`${API_ENDPOINT}/studies/count/staff/${staffData._id}/status/active`);
                setActiveStudiesCount(activeStudiesRes.data.item);

                // Sites count
                const sitesRes = await axios.get(`${API_ENDPOINT}/sites-count`);
                setSiteCount(sitesRes.data.item);

                // Site List
                const sitesListRes = await axios.get(`${API_ENDPOINT}/sites-oldest`);
                setSitesList(sitesListRes.data.items);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        // Cleanup function to prevent memory leaks
        return () => {
            setStaffStudyCount(null);
            setTotalStudyCount(null);
            setActiveStudiesCount(null);
            setSiteCount(null);
            setSitesList(null);
        };
    }, [API_ENDPOINT, staffData]);

    return (
        <div className="d-flex profile">
            <div>
                <CSidebar />
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                <Header />

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                        <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                            <h5>Dashboard</h5>
                        </div>

                        <div className='py-3'>
                            <div className="row mb-4">
                                <div className='col-lg-4 mb-3'>
                                    <div className='d-card p-3'>
                                        <div className='d-c-icon'>
                                            <MdLibraryBooks />
                                        </div>
                                        <div className='d-c-details'>
                                            <small className='mb-3'>Studies Count</small>
                                            <h5>
                                                {totalStudyCount !== undefined ? totalStudyCount : `Data Unavailable`}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <div className='d-card p-3'>
                                        <div className='d-c-icon'>
                                            <MdOutlineCollectionsBookmark />
                                        </div>
                                        <div className='d-c-details'>
                                            <small className='mb-3'>Active Studies</small>
                                            <h5>
                                                {activeStudiesCount !== undefined ? activeStudiesCount : `Data Unavailable`}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <div className='d-card p-3'>
                                        <div className='d-c-icon'>
                                            <FaSitemap />
                                        </div>
                                        <div className='d-c-details'>
                                            <small className='mb-3'>Registered Sites</small>
                                            <h5>
                                                {siteCount !== undefined ? `${siteCount}` : `Data Unavailable`}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-6 mb-3'>
                                    <div className='card'>
                                        <div className='card-header p-3'>
                                            <h6>Recent Study Activities</h6>
                                        </div>

                                        <div className='card-body p-3'>
                                            <ol className="list-group list-group-numbered">
                                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">Subheading</div>
                                                        Content for list item
                                                    </div>
                                                    <span className="badge bg-primary rounded-pill">14</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">Subheading</div>
                                                        Content for list item
                                                    </div>
                                                    <span className="badge bg-primary rounded-pill">14</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">Subheading</div>
                                                        Content for list item
                                                    </div>
                                                    <span className="badge bg-primary rounded-pill">14</span>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 mb-3'>
                                    <div className='card'>
                                        <div className='card-header p-3'>
                                            <h6>Recent Site Activities</h6>
                                        </div>

                                        <div className='card-body p-3'>
                                            <ol className="list-group list-group-numbered">
                                                {
                                                    sitesList?.length === 0
                                                        ? <>No data available</>
                                                        : (
                                                            <>
                                                                {
                                                                    (sitesList?.slice(0, 5) ?? []).map((e) =>
                                                                        <li className="list-group-item d-flex justify-content-between align-items-start" key={e._id}>
                                                                            <div className="ms-2 me-auto">
                                                                                <div className="fw-bold"> {e.site_name} </div>
                                                                                {e.type_of_site}
                                                                            </div>
                                                                            <span className="badge bg-primary rounded-pill"> {e.state_province}, {e.country} </span>
                                                                        </li>
                                                                    )
                                                                }
                                                                {
                                                                    sitesList?.length > 5 &&
                                                                    <li className="list-group-item">+ {sitesList.length - 5} more...</li>
                                                                }
                                                            </>
                                                        )
                                                }

                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;