import React, { useContext, useState } from 'react';
import CSidebar from '../../components/Nav/CSidebar';
import Header from '../../components/Header/Header';
import { NavLink, Outlet } from 'react-router-dom';
import { GlobalState } from '../../GlobalState';

function Profile(props) {
    const [navOpen, setNavOpen] = useState(false);

    const handleToggleNav = () => {
        setNavOpen(!navOpen);
    };

    const state = useContext(GlobalState);
    const [staffData] = state.staffData;

    return (
        <div className="d-flex profile">
            <div>
                <CSidebar />
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                <Header />

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                        <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                            <h5>My Account</h5>
                        </div>

                        <div className='py-2'>
                            <div className="nav-container">
                                <NavLink to="/my-account" className={({ isActive, isPending, isTransitioning }) =>
                                    [
                                        isPending ? "pending" : "",
                                        isActive ? "active" : "",
                                        isTransitioning ? "transitioning" : "",
                                    ].join(" ")
                                }>
                                    <button className="btn-b in-nav">
                                        <span className="me-2 fw-bold">  </span>
                                        My Account
                                    </button>
                                </NavLink>

                                <button className="hamburger" onClick={handleToggleNav}>
                                    &#9776;
                                </button>
                            </div>

                            <ul className={`mobile-page-nav py-1 ${navOpen ? 'open' : ''}`}>
                                <li>
                                    <NavLink to="/my-account/change-password" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Change Password
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/my-account/edit-profile/${staffData._id}`} className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Edit Profile
                                        </button>
                                    </NavLink>
                                </li>
                            </ul>

                            <ul className={`page-nav py-1 mb-3`}>
                                <li>
                                    <NavLink to="/my-account" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            My Account
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/my-account/change-password" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Change Password
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/my-account/edit-profile/${staffData._id}`} className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Edit Profile
                                        </button>
                                    </NavLink>
                                </li>
                                {/* <li>
                                <NavLink to="/studies/new-study" className={({ isActive, isPending, isTransitioning }) =>
                                    [
                                        isPending ? "pending" : "",
                                        isActive ? "active" : "",
                                        isTransitioning ? "transitioning" : "",
                                    ].join(" ")
                                }>
                                    <button className="btn-b in-nav">
                                        <span className="me-2 fw-bold">  </span>
                                        Timeline/Milestones
                                    </button>
                                </NavLink>
                            </li> */}
                            </ul>
                        </div>

                        <div className="py-3">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;