import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalState } from '../../GlobalState';
import Nodata from '../../utils/Nodata';

function StudyInformation(props) {
    const state = useContext(GlobalState);
    const [studyInterestsData] = state.studyInterestsApi.studyInterestsData;

    return (
        <div className='py-1'>
            <div className=''>
                <h6>Study Information</h6>

                <div className='py-3'>
                    {
                        studyInterestsData.length === 0 ?
                            <>
                                <Nodata />
                            </> :
                            <>
                                {
                                    studyInterestsData.map((e) =>
                                        <div className="card mb-3" key={e._id}>
                                            <div className="card-header d-flex justify-content-between">
                                                <span style={{ fontSize: '14px', fontWeight: 500 }}>
                                                    {e.study.study_title}
                                                </span>

                                                <span style={{ fontSize: '14px', fontWeight: 500, color: '#B81E23' }}>
                                                    {e.study.study_id}
                                                </span>
                                            </div>
                                            <div className="card-body d-flex justify-content-between">
                                                <div className=''>
                                                    <h6 className="card-title" style={{ fontSize: '14px', fontWeight: 500 }}>  {e.site.site_name}, {e.site.state_province}
                                                        <span style={{ fontWeight: '400' }}> has joined the waitlist</span>
                                                    </h6>
                                                    {/* <div>
                                                        {e.site.type_of_site}
                                                    </div>
                                                    <div>
                                                        {e.site.is_phase_one_site === true ? "Phase 1, " : ""} {e.site.is_phase_two_site === true ? "Phase 2, " : ""} {e.site.is_phase_three_site === true ? "Phase 3, " : ""} {e.site.is_phase_four_site === true ? "Phase 4 " : ""}

                                                        Research Site
                                                    </div> */}
                                                </div>

                                                <div>
                                                    <Link to={`/studies/study-information/${e._id}`} className="btn-a">View Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                    }

                </div>
            </div>
        </div>
    );
}

export default StudyInformation;