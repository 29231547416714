import React, { useContext, useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GlobalState } from "../../GlobalState";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const initialState = {
    study_title: "",
    study_description: "",
    study_status: "",
    study_state: "",
    indications: "",
    cro_name: "",
    sponsor_name: "",
    protocol_number: "",
    total_number_of_sites: "",
    preffered_investigator_specialty: "",
    experience: "",
    start_date: "",
    enrollment_period: "",
    study_duration: "",
    deadline_for_submission: "",
};

function NewStudy() {
    const [newStudy, setNewStudy] = useState(initialState);
    const {
        study_title,
        study_status,
        study_description,
        indications,
        cro_name,
        sponsor_name,
        protocol_number,
        total_number_of_sites,
        preffered_investigator_specialty,
        experience,
        start_date,
        enrollment_period,
        study_duration,
        deadline_for_submission,
    } = newStudy;

    const [equipmentNeeded, setEquipmentNeeded] = useState("");
    const [inclusionCriteria, setInclusionCriteria] = useState("");
    const [exclusionCriteria, setExclusionCriteria] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");

    const state = useContext(GlobalState);
    const [staffData] = state.staffData;
    const [, setAlert] = state.alert;
    const [studiesData] = state.studiesApi.studiesData;
    const [studyStatusData] = state.studyStatusApi.studyStatusData
    const [onEdit, setOnEdit] = useState(false);
    const [callback, setCallback] = state.studiesApi.callback;
    const param = useParams();

    const [studyFiles, setStudyFiles] = useState([])
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');

    const [uploadedFilesFQ, setUploadedFilesFQ] = useState([]);
    const [uploadedFilesCDA, setUploadedFilesCDA] = useState([]);
    const [uploadProgressFQ, setUploadProgressFQ] = useState(0);
    const [uploadProgressCDA, setUploadProgressCDA] = useState(0);
    const [uploadStatusFQ, setUploadStatusFQ] = useState('');
    const [uploadStatusCDA, setUploadStatusCDA] = useState('');

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    useEffect(() => {
        const getData = async () => {
            if (param.id) {
                setOnEdit(true);
                const res = await axios.get(`${API_ENDPOINT}/studies/${param.id}`)
                setNewStudy(res.data?.item);
                setUploadedFilesFQ(res.data?.item.feasibilityQuestionnaire);
                setUploadedFilesCDA(res.data?.item.clientDisclosureAgreement);
                setStudyFiles(res.data?.item.study_documents);
                setAdditionalInfo(res.data?.item.additionalInfo || "");
                setEquipmentNeeded(res.data?.item.equipmentNeeded || "");
                setInclusionCriteria(res.data?.item.inclusionCriteria || "");
                setExclusionCriteria(res.data?.item.exclusionCriteria || "");
            } else {
                setOnEdit(false);
                setNewStudy(initialState);
                setStudyFiles([]);
                setUploadedFilesCDA([]);
                setUploadedFilesFQ([]);
            }
        }
        getData()
    }, [param.id, studiesData, API_ENDPOINT]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewStudy((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleCKEditorChange = (name, setValue) => (_, editor) => {
        const data = editor.getData();
        setValue(data);
        setNewStudy((prevData) => ({
            ...prevData,
            [name]: data,
        }));
    };

    const submitFQDoc = async (event) => {
        try {
            const formData = new FormData();
            const files = event.target.files;

            if (files.length > 0) {
                for (const file of files) {
                    formData.append("files", file);
                }

                const uploadRes = await axios.post(
                    `${API_ENDPOINT}/studyFQDocs`,
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progressEvent) => {
                            setUploadProgressFQ(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                    }
                );

                const filesResponse = uploadRes.data.files;
                setUploadedFilesFQ(filesResponse);
                setUploadStatusFQ('success');

                // Clear upload status message after 2 seconds
                setTimeout(() => {
                    setUploadStatus('');
                }, 2000);
            }
        } catch (error) {
            console.error("Error uploading FQDoc:", error);
            setUploadStatusFQ('failure');
            // Handle errors if any
        }
    };

    const submitCDADoc = async (event) => {
        try {
            const formData = new FormData();
            const files = event.target.files;

            if (files.length > 0) {
                for (const file of files) {
                    formData.append("files", file);
                }

                const uploadRes = await axios.post(
                    `${API_ENDPOINT}/studyCDADocs`,
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progressEvent) => {
                            setUploadProgressCDA(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                    }
                );

                const filesResponse = uploadRes.data.files;
                setUploadedFilesCDA(filesResponse);
                setUploadStatusCDA('success');

                // Clear upload status message after 2 seconds
                setTimeout(() => {
                    setUploadStatus('');
                }, 2000);
            }
        } catch (error) {
            console.error("Error uploading CDADoc:", error);
            setUploadStatusCDA('failure');
            // Handle errors if any
        }
    };

    const uploadStudyFiles = async (event) => {
        try {
            const formData = new FormData();
            const files = event.target.files;

            if (files.length > 0) {
                for (const file of files) {
                    formData.append("files", file);
                }

                const uploadRes = await axios.post(
                    `${API_ENDPOINT}/study-docs`,
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progressEvent) => {
                            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                    }
                );

                const filesResponse = uploadRes.data.files;
                setStudyFiles(filesResponse);
                setUploadStatus('success');
                // You can handle the response as needed

                // Clear upload status message after 2 seconds
                setTimeout(() => {
                    setUploadStatus('');
                }, 2000);
            }
        } catch (error) {
            console.error("Error uploading files:", error);
            setUploadStatus('failure');
            // Handle errors if any
        }
    };


    const submitStudy = async (e) => {
        e.preventDefault();

        try {
            let studyData = { ...newStudy };

            // Set uploaded files into studyData
            studyData.study_documents = studyFiles;
            studyData.feasibilityQuestionnaire = uploadedFilesFQ;
            studyData.clientDisclosureAgreement = uploadedFilesCDA;

            // Create a new study or update an existing study
            if (onEdit) {
                // Edit existing study
                const res = await axios.put(
                    `${API_ENDPOINT}/studies/${param.id}`,
                    studyData
                );
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: res.data.msg,
                    successMsg: "",
                });
            } else {
                // Create a new study
                const res = await axios.post(`${API_ENDPOINT}/studies`, {
                    ...studyData,
                    staff: staffData._id,
                });
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: res.data.msg,
                    successMsg: "",
                });

                // Reset form fields after successful submission
                setNewStudy(initialState);
                setUploadedFilesFQ([]);
                setUploadedFilesCDA([]);
                setStudyFiles([]);
            }

            setTimeout(() => setAlert({ failure: false, success: false }), 2000);
            setCallback(!callback);
        } catch (error) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: "An unexpected error occurred",
                successMsg: "",
            });
            setTimeout(() => setAlert({ failure: false, success: false }), 2000);
        }
    };


    if (!newStudy || !studyFiles) {
        return (
            <>
                loading....
            </>
        )
    }

    return (
        <>
            <div className="">
                <div className="container">
                    <div className="mb-3">
                        <h4>Publish a new study</h4>
                    </div>

                    <div className="form">
                        <form onSubmit={submitStudy}>
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="bg-white mb-3">
                                        <div className="border-bottom pt-3 pb-2 px-3">
                                            <h6>Key Information</h6>
                                        </div>

                                        <div className="p-3">
                                            <div className="mb-3">
                                                <label htmlFor="study title" className="form-label">Study Title</label>
                                                <input type="name" className="form-control" id="study_title" aria-describedby="study_title" name="study_title"
                                                    value={study_title} onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="study_description" className="form-label">
                                                    Study description:
                                                </label>
                                                <textarea className="form-control" name="study_description" value={study_description} onChange={handleChange} />
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <label htmlFor="indications" className="form-label">Study Indication:</label>
                                                    <input type="text" className="form-control" id="indications" name="indications"
                                                        value={indications} onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label htmlFor="cro_name" className="form-label">CRO Name:</label>
                                                    <input type="text" className="form-control" id="cro_name" name="cro_name"
                                                        value={cro_name} onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label htmlFor="cro_name" className="form-label">Study Status:</label>
                                                    <select className="form-select form-select-md" name="study_status" value={study_status} onChange={handleChange}>
                                                        <option>...</option>
                                                        {
                                                            studyStatusData.map((e) =>
                                                                <option key={e._id} value={e.status}> {e.status} </option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <label htmlFor="sponsor_name" className="form-label">Sponsor Name:</label>
                                                    <input type="text" className="form-control" id="sponsor_name" name="sponsor_name"
                                                        value={sponsor_name} onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <label htmlFor="protocol_number" className="form-label">Protocol Number:</label>
                                                    <input type="text" className="form-control" id="protocol_number" name="protocol_number"
                                                        value={protocol_number} onChange={handleChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <label htmlFor="total_number_of_sites" className="form-label">Total Number of Sites:</label>
                                                    <input type="text" className="form-control" id="total_number_of_sites" name="total_number_of_sites"
                                                        value={total_number_of_sites} onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label htmlFor="preffered_investigator_specialty" className="form-label">Preffered PI Specialty:</label>
                                                    <input type="text" className="form-control" id="preffered_investigator_specialty" name="preffered_investigator_specialty"
                                                        value={preffered_investigator_specialty} onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <label htmlFor="experience" className="form-label">Experience:</label>
                                                    <input type="text" className="form-control" id="exampleInputPassword1" name="experience"
                                                        value={experience} onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-white mb-3">
                                        <div className="border-bottom pt-3 pb-2 px-3">
                                            <h6>Study Timeline</h6>
                                        </div>

                                        <div className="p-3">
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <label htmlFor="start_date" className="form-label">Start Date:</label>
                                                    <input type="date" className="form-control" id="start_date" name="start_date"
                                                        value={start_date} onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <label htmlFor="enrollment_period" className="form-label">Enrollment Period:</label>
                                                    <input type="text" className="form-control" id="enrollment_period" name="enrollment_period"
                                                        value={enrollment_period} onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <label htmlFor="study_duration" className="form-label">Study Duration:</label>
                                                    <input type="text" className="form-control" id="study_duration" name="study_duration"
                                                        value={study_duration} onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <label htmlFor="deadline_for_submission" className="form-label">Deadline for Submission:</label>
                                                    <input type="date" className="form-control" id="deadline_for_submission" name="deadline_for_submission"
                                                        value={deadline_for_submission} onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <label htmlFor="equipmentNeeded" className="form-label">
                                                Equipment Needed:
                                            </label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={equipmentNeeded}
                                                onChange={handleCKEditorChange(
                                                    "equipmentNeeded",
                                                    setEquipmentNeeded
                                                )}
                                            />
                                        </div>

                                        <div className="col-lg-6 mb-3">
                                            <label htmlFor="inclusionCriteria" className="form-label">
                                                Inclusion Criteria:
                                            </label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={inclusionCriteria}
                                                onChange={handleCKEditorChange(
                                                    "inclusionCriteria",
                                                    setInclusionCriteria
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <label htmlFor="exclusionCriteria" className="form-label">
                                                Exclusion Criteria:
                                            </label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={exclusionCriteria}
                                                onChange={handleCKEditorChange(
                                                    "exclusionCriteria",
                                                    setExclusionCriteria
                                                )}
                                            />
                                        </div>

                                        <div className="col-lg-6 mb-3">
                                            <label htmlFor="additionalInfo" className="form-label">
                                                Additional Information:
                                            </label>

                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    height: 300 // Set your desired height here (in pixels)
                                                }}
                                                data={additionalInfo}
                                                onChange={handleCKEditorChange(
                                                    "additionalInfo",
                                                    setAdditionalInfo
                                                )}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-4">
                                    <div className="bg-white mb-3">
                                        <div className="border-bottom pt-3 pb-2 px-3">
                                            <h6>Study Documents</h6>
                                        </div>

                                        <div className="p-3">
                                            <div className="">
                                                <div className="col-lg-12">
                                                    <input
                                                        type="file"
                                                        className="form-control form-control-md"
                                                        id="files"
                                                        onChange={uploadStudyFiles}
                                                        multiple
                                                    />
                                                </div>

                                                <div className="progress my-3">
                                                    <div className={`progress-bar ${uploadStatus === 'success' ? 'bg-success' : uploadStatus === 'failure' ? 'bg-danger' : ''}`} role="progressbar" style={{ width: `${uploadProgress}%` }} aria-valuenow={uploadProgress} aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <div className="mt-1">{uploadStatus === 'success' ? 'Upload Successful' : uploadStatus === 'failure' ? 'Upload Failed' : ''}</div>

                                                {/* Display uploaded files */}
                                                <div className="mt-3">
                                                    <p>Uploaded Study Files:</p>
                                                    <ul>
                                                        {studyFiles.map((file, index) => (
                                                            <li key={index}>{file.name}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <div className="bg-white border-bottom pt-3 pb-2 px-3">
                                            <h6 className="text-primary">Sponsor Documents</h6>
                                        </div>

                                        <div className="p-3">
                                            <div className="row">
                                                <div className="col-lg-12 bg-white mb-4 py-2">
                                                    <label className="form-label" htmlFor="fq">Feasibility Questionnaire (FQ) </label>
                                                    <input type="file" className="form-control form-control-md"
                                                        id="fqFiles"
                                                        onChange={submitFQDoc}
                                                        multiple
                                                    />
                                                    <div className="progress my-3">
                                                        <div className={`progress-bar ${uploadStatusFQ === 'success' ? 'bg-success' : uploadStatusFQ === 'failure' ? 'bg-danger' : ''}`} role="progressbar" style={{ width: `${uploadProgressFQ}%` }} aria-valuenow={uploadProgressFQ} aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <div className="mt-1">{uploadStatusFQ === 'success' ? 'Upload Successful' : uploadStatusFQ === 'failure' ? 'Upload Failed' : ''}</div>
                                                    <div className="mt-3">
                                                        <p>Uploaded FQDoc Files:</p>
                                                        <ul>
                                                            {uploadedFilesFQ.map((file, index) => (
                                                                <li key={index}>{file.name}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 bg-white mb-4 py-2">
                                                    <label className="form-label" htmlFor="cda">Client Disclosure Agreement (CDA)</label>
                                                    <input type="file" className="form-control form-control-md"
                                                        id="cdaFiles"
                                                        onChange={submitCDADoc}
                                                        multiple
                                                    />
                                                    <div className="progress my-3">
                                                        <div className={`progress-bar ${uploadStatusCDA === 'success' ? 'bg-success' : uploadStatusCDA === 'failure' ? 'bg-danger' : ''}`} role="progressbar" style={{ width: `${uploadProgressCDA}%` }} aria-valuenow={uploadProgressCDA} aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <div className="mt-1">{uploadStatusCDA === 'success' ? 'Upload Successful' : uploadStatusCDA === 'failure' ? 'Upload Failed' : ''}</div>
                                                    <div className="mt-3">
                                                        <p>Uploaded CDADoc Files:</p>
                                                        <ul>
                                                            {uploadedFilesCDA.map((file, index) => (
                                                                <li key={index}>{file.name}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="border-top mt-4 py-3">
                                <Link to="/studies">
                                    <button className="btn-d me-2">Cancel</button>
                                </Link>
                                <button type="submit" className="btn-a">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewStudy;