import React, { useContext, useEffect, useState } from 'react';
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

const initialState = {
    firstname: "",
    othernames: "",
    lastname: "",
    phonenumber: "",
    department: "",
    designation: ""
}

function EditProfile(props) {
    const [staffDetails, setStaffDetails] = useState(initialState);
    const { firstname, othernames, lastname, phonenumber } = staffDetails;
  
    const state = useContext(GlobalState);
    const [staffData] = state.staffData;
    const itAdmin = null;
    const [token] = state.token;
  
    const [, setAlert] = state.alert;
  
    const [images, setImages] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const param = useParams();
  
    useEffect(() => {
      if (param.id) {
        setStaffDetails(staffData);
        setImages(staffData.images);
      } else {
        setStaffDetails(initialState);
        setImages(false);
      }
    }, [param.id, staffData]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setStaffDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    };
  
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  
    const submitForm = async (e) => {
      e.preventDefault();
  
      try {
        const res = await axios.post(`${API_ENDPOINT}/staffs/${staffData._id}`, { ...staffDetails });
        setAlert({
          failure: false,
          success: true,
          failureMsg: res.data.msg,
          successMsg: ''
        });
        setTimeout(() => setAlert({ ...alert, success: false }), 2000);
      } catch (error) {
        setAlert({
          failure: true,
          success: false,
          failureMsg: error.response.data.msg,
          successMsg: ''
        });
        setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
      }
    };

    const handleUpload = async e => {
        e.preventDefault()
        try {
            if (!itAdmin) return alert("You're not an admin ")
            const file = e.target.files[0]

            if (!file) return alert("file does not exist.")

            if (file.size > 1024 * 1024)
                return alert("size is too large!")

            if (file.type !== "image/jpeg" && file.type !== 'image/png')
                return alert("File format is incorrect.")

            let formData = new FormData()
            formData.append('file', file)

            setLoading(true)
            const res = await axios.post(`${API_ENDPOINT}/staffs/${staffData._id}`, formData, {
                headers: { 'content-type': "multipart/form-data", Authorization: token }
            })

            setLoading(false)
            console.log(res)
            setImages(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroy = async () => {
        try {
            if (!itAdmin) return alert("Youàre not an Admin")
            await axios.post('/api/destroy', { public_id: images.public_id }, {
                headers: { Authorization: token }
            })

            setLoading(false)
            setImages(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }


    const styleUpload = {
        display: images ? "block" : "none"
    }

    return (
        <div>
            <div className='row'>
                <div className='col-lg-5'>
                    <h6 className='fs-6 fw-bold mb-5'>Edit Profile</h6>

                    <div className='my-3 d-flex align-content-center justify-content-center'>
                        <div className='profile-pic-large'>
                            <img src={staffDetails.profile_img} alt="staff-pic" />
                        </div>
                    </div>

                    <div className='d-flex justify-content-center py-2'>
                        <button type="button" className='btn-d' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Change Profile Image </button>
                    </div>
                </div>
                <div className='col-lg-7'>

                    <div className='p-2'>
                        <form onSubmit={submitForm}>
                            <div className='col mb-3'>
                                <label htmlFor='fullname' className='form-label'>Firstname</label>
                                <input type='text' className='form-control form-control-md' id='firstname' name='firstname'
                                    value={firstname} onChange={handleChange}
                                />
                            </div>

                            <div className='col mb-3'>
                                <label htmlFor='fullname' className='form-label'>Othernames</label>
                                <input type='text' className='form-control form-control-md' id='othernames' name='othernames'
                                    value={othernames} onChange={handleChange}
                                />
                            </div>

                            <div className='col mb-3'>
                                <label htmlFor='fullname' className='form-label'>Lastname</label>
                                <input type='text' className='form-control form-control-md' id='lastname' name='lastname'
                                    value={lastname} onChange={handleChange}
                                />
                            </div>

                            <div className='col mb-3'>
                                <label htmlFor='fullname' className='form-label'>Phone number</label>
                                <input type='text' className='form-control form-control-md' id='phonenumber' name='phonenumber'
                                    value={phonenumber} onChange={handleChange}
                                />
                            </div>

                            <div className='d-flex justify-content-start py-3'>
                                <Link to="/my-account"><button className='btn-d me-2'>Cancel</button></Link>
                                <button className='btn-b' type='submit'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Change Profile Picture</h1>
                            <button className="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="upload">
                                <input type="file" name="file" id="file_up" onChange={handleUpload} />

                                {
                                    loading ?
                                        <div id="file_img">
                                            <p>Loading....</p>
                                        </div>
                                        :
                                        <div id="file_img" style={styleUpload}>
                                            <img src={images ? images.url : ''} alt="" />
                                            <span onClick={handleDestroy}>X</span>
                                        </div>
                                }

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Understood</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditProfile;