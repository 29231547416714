import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// IMPORTED DATA APIs
import RolesAPI from './api/RolesAPI';
import PermissionsAPI from './api/PermissionsAPI';
import UsersAPI from './api/UsersAPI';
import StaffsAPI from './api/StaffsAPI';
import StudiesAPI from './api/StudiesAPI';
import CapabilitiesAPI from './api/CapabilitiesAPI';
import FacilitiesAPI from './api/FacilitiesAPI';
import TherapeuticAreasAPI from './api/TherapeuticAreasAPI';
import DepartmentsAPI from './api/DepartmentsAPI';
import SitesAPI from './api/SitesAPI';
// import Loader from './utils/Loader';
import StudyInterestsAPI from './api/StudyInterestsAPI';
import StudyStatusAPI from './api/StudyStatusAPI';

// GLOBALSTATE DECLARE
export const GlobalState = createContext();

export const DataProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState(false);
    const [alert, setAlert] = useState({ success: false, failure: false, successMsg: '', failureMsg: '' });
    const [token, setToken] = useState('');
    const [isLogged, setIsLogged] = useState(false);
    const [staffData, setStaffData] = useState({});
    const [ict, setIct] = useState(false);
    const [mgmt, setMgmt] = useState(false);
    const [clientRel, setClientRel] = useState(false);
    const [studyMgmt, setStudyMgmt] = useState(false);
    const [regulatoryDept, setRegulatoryDept] = useState(false);
    const [contractsDept, setContractsDept] = useState(false);
    

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            try {
                const staffLogin = localStorage.getItem('staffLogin');
                if (staffLogin) {
                    const res = await axios.post(
                        `${API_ENDPOINT}/staffs/refresh_token`,
                        null,
                        { withCredentials: true }
                    );
                    setToken(res?.data?.access_token || ''); // Added optional chaining here
                    setIsLogged(true);
                    setStaffData(res?.data?.staff || {}); // Added optional chaining here
                    // console.log(res.data?.staff.department)

                    if (res.data.staff.department.name === 'ICT') {
                        setIct(true);
                    } 
                    if (res.data.staff.department.name === 'Management') {
                        setMgmt(true); 
                    } 
                    if (res.data.staff.department.name === 'Regulatory') {
                        setRegulatoryDept(true); 
                    } 
                    if (res.data.staff.department.name === 'Study Management') {
                        setStudyMgmt(true); 
                    } 
                    if (res.data.staff.department.name === 'Client Relations') {
                        setClientRel(true);
                    }
                    if (res.data.staff.department.name === 'Contract and Budget') {
                        setContractsDept(true);
                    }

                    // Loop through departments to set state
                    // if (res?.data?.staff?.department) {
                    //     for (const department of res.data.staff.department) {
                    //         console.log('Department:', department); // Log each department
                    //         if (department.name === 'ICT') {
                    //             setIct(true);
                    //             break; // Exit loop after first match
                    //         } else if (department.name === 'Management') {
                    //             setMgmt(true);
                    //             break;
                    //         } else if (department.name === 'Client Relations') {
                    //             setClientRel(true);
                    //             break;
                    //         }
                    //     }
                    // }
                }
            } catch (err) {
                console.log(err.response?.data?.msg); // Added optional chaining here
                // setAlert({ ...alert, failure: true, failureMsg: err.response?.data?.msg }); // Added optional chaining here
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, [API_ENDPOINT]); // Removed 'alert' from dependencies array

    const state = {
        loading: [loading, setLoading],
        notify: [notify, setNotify],
        alert: [alert, setAlert],
        token: [token, setToken],
        isLogged: [isLogged, setIsLogged],
        staffData: [staffData, setStaffData],
        rolesApi: RolesAPI(),
        permissionsApi: PermissionsAPI(),
        usersApi: UsersAPI(),
        staffsApi: StaffsAPI(),
        studiesApi: StudiesAPI(staffData?._id || null), // Added optional chaining here
        facilitiesApi: FacilitiesAPI(),
        capabilitiesApi: CapabilitiesAPI(),
        therapeuticAreasApi: TherapeuticAreasAPI(),
        departmentsApi: DepartmentsAPI(),
        sitesApi: SitesAPI(),
        ict: [ict, setIct],
        mgmt: [mgmt, setMgmt],
        regulatoryDept: [regulatoryDept, setRegulatoryDept], 
        clientRel: [clientRel, setClientRel],
        studyMgmt: [studyMgmt, setStudyMgmt],
        contractsDept: [contractsDept, setContractsDept],
        studyInterestsApi: StudyInterestsAPI(staffData?._id || null),
        studyStatusApi: StudyStatusAPI(),
    };

    return (
        <GlobalState.Provider value={state}>
            {/* {loading && <Loader />} */}
            {children}
        </GlobalState.Provider>
    );
};
