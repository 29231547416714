import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SiStudyverse } from "react-icons/si";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { BiSolidInstitution } from "react-icons/bi";
import { FaDownload } from "react-icons/fa6";
import axios from 'axios';
import Loader from '../../utils/Loader';
import { GlobalState } from '../../GlobalState';

function StudyInterestInfo(props) {
    const { id } = useParams()
    const [studyInterest, setStudyInterest] = useState("")

    const state = useContext(GlobalState)
    const [, setAlert] = state.alert;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    useEffect(() => {
        if (id) {
            const getData = async () => {
                try {
                    const res = await axios.get(`${API_ENDPOINT}/study-interests/${id}`);
                    setStudyInterest(res.data.item)
                } catch (error) {
                    console.error("Error fetching study data:", error);
                    // Handle error (e.g., set an error state or show an alert)
                }
            }
            getData()
        }
    }, [API_ENDPOINT, id])

    const downloadFQDocument = async (fileUrl) => {
        try {
            // Split the file URL to extract the last 3 sections
            const sections = fileUrl.split('/').slice(-3);
            const downloadEndpoint = `${API_ENDPOINT}/download/${sections.join('/')}`;

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = downloadEndpoint;
            link.setAttribute('target', '_blank'); // Open in a new tab
            document.body.appendChild(link);

            // Simulate click to trigger download
            link.click();

            // Cleanup
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            // Handle error if needed
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    };

    // Download Client Disclosure Agreement
    const downloadCDADocument = async (fileUrl) => {
        try {
            // Split the file URL to extract the last 3 sections
            const sections = fileUrl.split('/').slice(-3);
            const downloadEndpoint = `${API_ENDPOINT}/download/${sections.join('/')}`;

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = downloadEndpoint;
            link.setAttribute('target', '_blank'); // Open in a new tab
            document.body.appendChild(link);

            // Simulate click to trigger download
            link.click();

            // Cleanup
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            // Handle error if needed
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    const { study, site, user, staff } = studyInterest;

    if (!study || !site || !user || !staff) {
        return (
            <>
                <Loader />
            </>
        )
    }

    return (
        <div className='py-1'>
            <div className=''>
                {/* <h6>Action </h6> */}

                <div className='row'>

                    <div className='col-lg-6'>
                        <div className="card" style={{ minHeight: '250px' }}>
                            <div className='card-header'>
                                <span className='me-2'>
                                    <SiStudyverse />
                                </span>
                                Study
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="d-flex">
                                            <p className='me-2'>Study Title </p>
                                            <h6>  {study.study_title} </h6>
                                        </div>
                                        <div className="d-flex">
                                            <p className='me-2'>Sponsor </p>
                                            <h6> {study.sponsor_name} </h6>
                                        </div>
                                        <div className="d-flex">
                                            <p className='me-2'>CRO Name </p>
                                            <h6>  {study.cro_name} </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="d-flex">
                                            <p className='me-2'>Study ID </p>
                                            <h6> {study.study_id} </h6>
                                        </div>
                                        <div className="d-flex">
                                            <p className='me-2'>Start Date </p>
                                            <h6> {study.start_date} </h6>
                                        </div>
                                        <div className="d-flex">
                                            <p className='me-2'>Duration </p>
                                            <h6> {study.study_duration} </h6>
                                        </div>

                                    </div>
                                </div>

                                <button className='btn btn-sm btn-primary'>See more</button>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className="card" style={{ minHeight: '250px' }}>
                            <div className="card-header">
                                <span className='me-2'>
                                    <BiSolidInstitution />
                                </span>
                                Site
                            </div>
                            <div className="card-body">
                                <div className="d-flex mb-2">
                                    <p className='me-2'>Site Name </p>
                                    <h6> {site.site_name} </h6>
                                </div>
                                <div className="d-flex mb-2">
                                    <p className='me-2'> Phases </p>
                                    <h6>
                                        {site.is_phase_one_site === true ? "Phase 1, " : ""} {site.is_phase_two_site === true ? "Phase 2, " : ""} {site.is_phase_three_site === true ? "Phase 3, " : ""} {site.is_phase_four_site === true ? "Phase 4 " : ""}
                                    </h6>
                                </div>

                                <div className="d-flex mb-2">
                                    <p className='me-2'>Address </p>
                                    <h6> {site.state_province}, {site.zip_postcode} </h6>
                                </div>

                                <button className='btn btn-sm btn-primary'>See more</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-4 pt-3'>
                    <div className='col-lg-8'>
                        <h6 className='mb-3'>Timeline</h6>


                        {
                            studyInterest.joinedWaitlist ?
                                <div className='card mb-3'>
                                    <div className='card-body'>
                                        <div className='d-flex'>
                                            <span className='me-2'>
                                                <MdOutlineDoubleArrow />
                                            </span>
                                            <div>Site has joined the waitlist</div>
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }

                        {
                            studyInterest.completedFeasibilityQuestionnaire ?
                                <div className='card mb-3'>
                                    <div className='card-body'>
                                        <div className='d-flex'>
                                            <span className='me-2'>
                                                <MdOutlineDoubleArrow />
                                            </span>
                                            <div>Feasibility Questionnaire has been submitted</div>
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }

                        {
                            studyInterest.completedClientDisclosureAgreement ?
                                <div className='card mb-3'>
                                    <div className='card-body'>
                                        <div className='d-flex'>
                                            <span className='me-2'>
                                                <MdOutlineDoubleArrow />
                                            </span>
                                            <div>Client Disclosure Agreement has been submitted</div>
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }
                    </div>

                    <div className='col-lg-4'>
                        <h6 className='mb-3'>Files</h6>

                        {
                            studyInterest.completedFeasibilityQuestionnaire ?
                                <>
                                    {
                                        studyInterest.feasibilityQuestionnaireDocument.map((e, i) =>
                                            <div className='card mb-3' key={i}>
                                                <div className='card-body'>
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <span className='me-2'>
                                                                <MdOutlineDoubleArrow />
                                                            </span>
                                                            FQ submitted
                                                        </div>

                                                        <button className='btn btn-sm btn-dark'
                                                            onClick={() => downloadFQDocument(e.url)}
                                                        >
                                                            view
                                                            <span className='ms-2'>
                                                                <FaDownload />
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                                : <>
                                    <div className='card'>
                                        <div className='card-body'>
                                            No files available yet
                                        </div>
                                    </div>
                                </>
                        }

                        {
                            studyInterest.completedFeasibilityQuestionnaire && studyInterest.completedClientDisclosureAgreement ?
                                <>
                                    {
                                        studyInterest.clientDisclosureAgreementDocument.map((e, i) =>
                                            <div className='card mb-3' key={i}>
                                                <div className='card-body'>
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <span className='me-2'>
                                                                <MdOutlineDoubleArrow />
                                                            </span>
                                                            CDA submitted
                                                        </div>

                                                        <button className='btn btn-sm btn-dark'
                                                            onClick={() => downloadCDADocument(e.url)}
                                                        >
                                                            view
                                                            <span className='ms-2'>
                                                                <FaDownload />
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                                : <>
                                    " "
                                </>

                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudyInterestInfo;