import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalState } from '../../GlobalState';

function ProfileDetails(props) {
    const state = useContext(GlobalState);
    const [staffData] = state.staffData;

    return (
        <>
            <div className=''>
                {/* <div className='d-flex justify-content-end mb-4'>
                    <Link to={`/my-account/edit-profile/${staffData._id}`}><button className='btn-b'>Edit Porfile</button></Link>
                </div> */}

                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='acc0img'>
                            <img src={staffData.profile_img} alt="staff-img" />
                        </div>
                    </div>
                    <div className='col-lg-8 bg-white p-3 rounded-2'>
                        <div className='row'>
                            <div className='col-lg-4 mb-3'>
                                <small>Lastname:</small>
                                <h6> {staffData.lastname} </h6>
                            </div>
                            <div className='col-lg-4 mb-3'>
                                <small>Firstname:</small>
                                <h6> {staffData.firstname} </h6>
                            </div>
                            <div className='col-lg-4 mb-3'>
                                <small>Other names:</small>
                                <h6> {staffData.othernames ? `${staffData.othernames}` : "-"} </h6>
                            </div>
                        </div>


                        <div className='col mb-3'>
                            <small>Staff Id:</small>
                            <h6> {staffData.staff_ID ? `${staffData.staff_ID}` : "nil"} </h6>
                        </div>

                        <div className='col mb-3'>
                            <small>Email:</small>
                            <h6> {staffData.email ? `${staffData.email}` : "nil"} </h6>
                        </div>

                        <div className='col mb-3'>
                            <small>Phone number:</small>
                            <h6> {staffData.phonenumber ? `${staffData._id}` : "nil"} </h6>
                        </div>

                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <small>Dapertment:</small>
                                <h6> {staffData.department.name ? `${staffData.department.name}` : "nil"} </h6>
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <small>Designation:</small>
                                <h6> {staffData.designation ? `${staffData.designation}` : "nil"} </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfileDetails;