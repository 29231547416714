import React from 'react';

function SecondaryContact(props) {
    return (
        <div>
            <div className="bg-white p-3">
                <div className="si-sec-b">
                    <h6>Fullname</h6>
                    <p>John Doe</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 si-sec-b">
                        <h6>Title</h6>
                        <p>Office Director</p>
                    </div>
                    <div className="col-lg-6 si-sec-b">
                        <h6>Email</h6>
                        <p>Office@mail.com</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 si-sec-b">
                        <h6>Primary Phone Number</h6>
                        <p>Office Director</p>
                    </div>
                    <div className="col-lg-6 si-sec-b">
                        <h6>Secondary Phone Number</h6>
                        <p>Office@mail.com</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecondaryContact;