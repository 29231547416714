import React from 'react';

function Capabilities({ data }) {
    return (
        <div>
            <div className="bg-white p-3">
                <div className="row">
                    {
                        data.capabilities.length === 0 ?
                            <> <h6>No data available yet</h6> </>
                            : <>
                                {
                                    data.capabilities.map((e) =>
                                        <div className="col-lg-4 si-sec-b" key={e._id}>
                                            <li> {e.name} </li>
                                        </div>
                                    )
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Capabilities;