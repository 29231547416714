import React from 'react';

function StudiesBudget(props) {
    return (
        <div>
            <h6>Studies Budget Page</h6>
        </div>
    );
}

export default StudiesBudget;