import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AboutInfo from '../../components/site/AboutInfo';
import SecondaryContact from '../../components/site/SecondaryContact';
import Investigators from '../../components/site/Investigators';
import Facilities from '../../components/site/Facilities';
import Capabilities from '../../components/site/Capabilities';
import TherapeuticAreas from '../../components/site/TherapeuticAreas';
import Documents from '../../components/site/Documents';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function SiteDetails(props) {
    const { id } = useParams()
    const [siteDetails, setSiteDetails] = useState([]);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    useEffect(() => {
        if (id) {
            const getSiteDetails = async () => {
                const res = await axios.get(`${API_ENDPOINT}/sites/${id}`);
                // console.log(res)
                setSiteDetails(res.data.item)
            }
            getSiteDetails()
        }
    }, [API_ENDPOINT, id])

    return (
        <div>
            <div className='card'>
                <div className='p-3 border-bottom'>
                    <div className='row'>
                        <div className='col-lg-6 si-sec-b'>
                            <small>Name of site:</small>
                            <h6> {siteDetails.site_name} </h6>
                        </div>

                        <div className='col-lg-6 si-sec-b'>
                            <small>Type of site:</small>
                            <h6> {siteDetails.type_of_site} </h6>
                        </div>

                        <div className='col-lg-6 si-sec-b'>
                            <small>Site phone number:</small>
                            <h6> {siteDetails.site_phone_number} </h6>
                        </div>

                        <div className='col-lg-6 si-sec-b'>
                            <small>Study phase(s) of interest:</small>
                            <h6>
                                {siteDetails.is_phase_one_site === true ? "Phase 1, " : ""}
                                {siteDetails.is_phase_two_site === true ? "Phase 2, " : ""}
                                {siteDetails.is_phase_three_site === true ? "Phase 3, " : ""}
                                {siteDetails.is_phase_four_site === true ? "Phase 4 " : ""}
                            </h6>
                        </div>

                        <div className='col-lg-6 si-sec-b'>
                            <small>Primary Phone</small>
                            <h6>123456789</h6>
                        </div>
                    </div>

                    <div className='py-3'>
                        <Tabs>
                            <TabList>
                                <Tab>About</Tab>
                                <Tab>Secondary Contact</Tab>
                                <Tab>Investigators</Tab>
                                <Tab>Facilities</Tab>
                                <Tab>Capabilities</Tab>
                                <Tab>Therapeutic Areas</Tab>
                                <Tab>Documents</Tab>
                            </TabList>
                            <TabPanel>
                                <AboutInfo data={siteDetails} />
                            </TabPanel>
                            <TabPanel>
                                <SecondaryContact data={siteDetails} />
                            </TabPanel>
                            <TabPanel>
                                <Investigators data={siteDetails} />
                            </TabPanel>
                            <TabPanel>
                                <Facilities data={siteDetails} />
                            </TabPanel>
                            <TabPanel>
                                <Capabilities data={siteDetails} />
                            </TabPanel>
                            <TabPanel>
                                <TherapeuticAreas data={siteDetails}  />
                            </TabPanel>
                            <TabPanel>
                                <Documents data={siteDetails} />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SiteDetails;