import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { GlobalState } from '../../GlobalState';
import Logo from '../../assets/img/phx-trans.png'


const initialState = {
    email: "",
    password: ""
}

function Login() {
    const [details, setDetails] = useState(initialState);
    const { email, password } = details

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    const state = useContext(GlobalState)
    const [, setAlert] = state.alert;
    const [typePass, setTypePass] = useState(false)

    const [quote, setQuote] = useState("")
    const [origin, setOrigin] = useState("")

    useEffect(() => {
        const getQuotes = async () => {
            const options = {
                method: 'GET',
                url: 'https://quotes15.p.rapidapi.com/quotes/random/',
                headers: {
                    'X-RapidAPI-Key': '4eebe14935msh4ff6405852571c0p17550djsnc8a3c2188a8b',
                    'X-RapidAPI-Host': 'quotes15.p.rapidapi.com'
                }
            };

            try {
                const response = await axios.request(options);
                // console.log(response.data);
                setQuote(response.data.content)
                setOrigin(response.data.originator.name)
            } catch (error) {
                console.error(error);
            }
        }
        getQuotes()
    }, [])

    const handleChange = e => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value })
    }

    const handleLogin = async (e) => {
        e.preventDefault()

        if (!email || !password) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }

        try {
            const res = await axios.post(
                `${API_ENDPOINT}/staffs/login`,
                { ...details },
                { withCredentials: true }
            )
            // console.log(res)
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setDetails(initialState)
            localStorage.setItem('staffLogin', true)
            window.location.href = "/";
        } catch (error) {
            // console.log(error)
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }
    return (
        <div className='auth-page'>
            <div className='col-55 auth-screen-img'>
                <img src={Logo} className='auth-logo' alt="company-logo" />
                <div className='sec-quotes'>
                    <div className='a-quote'>
                        <h5> {quote} </h5>
                        <h6> - {origin} </h6>
                    </div>
                </div>
            </div>
            <div className='col-45'>
                <div className='form-area'>
                    <h6>Hello,</h6>
                    <h5>Welcome!</h5>

                    <div className='lform'>
                        <form onSubmit={handleLogin}>
                            <div className='mb-3'>
                                <label htmlFor='email' className='form-label' >Email Address:</label>
                                <input type='text' className='form-control form-control-md' id='email' name='email'
                                    value={email} onChange={handleChange}
                                />
                            </div>

                            <div className='mb-3' >
                                <div className='pass'>
                                    <label htmlFor='password' className='form-label' >Password:</label>
                                    <input type={typePass ? "text" : "password"} id="password" name="password" className="form-control form-control-md"
                                        value={password} onChange={handleChange}
                                    />

                                    <small onClick={() => setTypePass(!typePass)} >
                                        {typePass ? 'Hide' : 'Show'}
                                    </small>
                                </div>
                            </div>

                            <div className='mb-3'>
                                <button type="submit" className='btn-a'> Login </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;