import React, { useContext } from 'react';
import Toast from './Toast';
import { GlobalState } from '../GlobalState';

export default function Alert() {
    const state = useContext(GlobalState);
    const [alert, setAlert] = state.alert;

    return (
        <div>
            {alert.failure && (
                <Toast
                    msg={{ title: 'Error', body: alert.failureMsg }}
                    handleShow={() => setAlert({ ...alert, failure: false })}
                    bgColor="bg-danger"
                />
            )}

            {alert.success && (
                <Toast
                    msg={{ title: 'Success', body: alert.successMsg }}
                    handleShow={() => setAlert({ ...alert, success: false })}
                    bgColor="bg-success"
                />
            )}
        </div>
    );
}


