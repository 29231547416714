import React, { useContext, useState } from 'react';
import { GlobalState } from '../../GlobalState';
import axios from 'axios';

const initialState = {
    name: ""
}

function Depts(props) {
    const [details, setDetails] = useState(initialState)
    const { name } = details;

    const [editDetails, setEditDetails] = useState(initialState);
    const [editMode, setEditMode] = useState(false);

    const state = useContext(GlobalState);
    const [departmentsData] = state.departmentsApi.departmentsData;
    const [callback, setCallback] = state.departmentsApi.callback;
    const [, setAlert] = state.alert;

    const handleChange = e => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value })
    }

    const editData = (department) => {
        setEditDetails(department);
        setEditMode(true);
        // Open the modal for editing
        // You may need to use a library or implement your own modal logic
    };

    const cancelEdit = () => {
        setEditDetails(initialState);
        setEditMode(false);
        // Close the modal for editing
    };

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const submitForm = async (e) => {
        e.preventDefault();

        try {
            if (!editMode && !name) {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: "one or more fields are empty",
                    successMsg: ''
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
                return;
            }

            const endpoint = editMode
                ? `${API_ENDPOINT}/departments/${editDetails._id}`
                : `${API_ENDPOINT}/departments`;

            const response = await (editMode
                ? axios.put(endpoint, editDetails)
                : axios.post(endpoint, details));

            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: ''
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                setCallback(!callback);
                setDetails(initialState);
            } else {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: response.data.msg,
                    successMsg: ''
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            }


        } catch (error) {
            console.error('Error submitting data:', error);
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: ''
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    const deleteData = async (id) => {
        try {
            const response = await axios.delete(`${API_ENDPOINT}/departments/${id}`);
            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: ''
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                setCallback(!callback);
            } else {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: response.data.msg,
                    successMsg: ''
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: ''
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    // if (!departmentsData) {
    //     return (
    //         <>Loading....</>
    //     )
    // }


    return (
        <div>
            <div className='d-flex justify-content-between mb-3'>
                <h6>Manage Departments</h6>
                <button type="button" class="btn-b" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Add department</button>
            </div>

            <div className='card'>
                <div className='card-header'>
                    <h6>List of staff departments</h6>
                </div>

                <div className='card-body'>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name of Department</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    departmentsData.length === 0 ?
                                        <>
                                            <div className='text-center py-3 w-100'>No information available yet</div>
                                        </>
                                        : <>
                                            {
                                                departmentsData.map((e, i) =>
                                                    <tr key={e._id}>
                                                        <td> {i + 1} </td>
                                                        <td> {e.name} </td>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <button className='btn-b me-2'
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#staticBackdrop"
                                                                    onClick={() => editData(e)}
                                                                >Edit</button>
                                                                <button className='btn-d me-2'
                                                                    onClick={() => deleteData(e._id)}
                                                                >Delete</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {/* <!-- Modal --> */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">
                                {editMode ? 'Edit' : 'Add new'} department
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={submitForm}>
                            <div className="modal-body">
                                <div className='py-3'>
                                    <label className='form-label'>Name of department</label>
                                    <input type="text" className='form-control form-control-md' id='name' name='name'
                                        value={editMode ? editDetails.name : name}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn-d" data-bs-dismiss="modal" onClick={cancelEdit} >Close</button>
                                <button type="submit" className="btn-a">
                                    {editMode ? 'Save Changes' : 'Save'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Depts;