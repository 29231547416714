import React from 'react';
import noData from '../assets/img/nodata.jpg';

function Nodata(props) {
    return (
        <div className='p-4'>
            <div className='d-flex justify-content-center align-self-center'>
                <div className='bg-white p-4 text-center' style={{ maxWidth: '600px', boxShadow: '2px 2px 2px 2px #f1f1f1' }}>
                    <img src={noData} alt='no-data-icon' style={{ maxHeight: '240px' }} />

                    <h4 className='text-capitalize fw-bolder mb-3'> No data available</h4>

                    <small className='fs-6 px-5'>
                        Sorry, there are no studies currently available at the moment, please try again later.
                    </small>
                </div>
            </div>
        </div>
    );
}

export default Nodata;