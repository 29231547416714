import React, { useContext, useState } from 'react';
import { GlobalState } from '../../GlobalState';
import axios from 'axios';

const initialState = {
    name: ""
}

function CapabilitiesDetails() {
    const [details, setDetails] = useState(initialState)
    const { name } = details;

    const state = useContext(GlobalState);
    const [capabilitiesData] = state.capabilitiesApi.capabilitiesData;
    const [callback, setCallback] = state.capabilitiesApi.callback;
    const [, setAlert] = state.alert;

    const handleChange = e => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value })
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const submitForm = async (e) => {
        e.preventDefault();

        try {
            if (!details) {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: 'One or more fields are empty',
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
                return;
            }
            const response = await axios.post(`${API_ENDPOINT}/site-capabilities`, { ...details });
            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: ''
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                setCallback(!callback);
                setDetails(initialState);
            } else {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: response.data.msg,
                    successMsg: ''
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: ''
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    return (
        <div>
            <div className='d-flex justify-content-end mb-3'>
                <button type="button" class="btn-b" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Add new capability</button>
            </div>

            <div className='card'>
                <div className='card-header'>
                    <h6>Capabilities List</h6>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <th>#</th>
                                <th>Name</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                            {
                                    capabilitiesData.length === 0 ?
                                        <>
                                            <div  className='text-center py-3 w-100'>No information available yet</div>
                                        </>
                                        : <>
                                            {
                                                capabilitiesData.map((e, i) =>
                                                    <tr key={e._id}>
                                                        <td> {i + 1} </td>
                                                        <td> {e.name} </td>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <button className='btn-b me-2'>Edit</button>
                                                                <button className='btn-d me-2'>Delete</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Add new site capability</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={submitForm}>
                            <div className="modal-body">
                                
                                <div className='py-3'>
                                    <label className='form-label'>Name of capability</label>
                                    <input type="text" className='form-control form-control-md' id='name' name='name'
                                        value={name} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn-d" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn-a">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CapabilitiesDetails;