import { useState, useEffect } from 'react';
import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function StaffsAPI() {
    const [staffsData, setStaffsData] = useState([]);
    const [callback, setCallback] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [result, setResult] = useState(0);
    const [total, setTotal] = useState(0);
    const [sortBy, setSortBy] = useState('');
    const [department, setDepartment] = useState('');

    useEffect(() => {
        const getStaffs = async () => {
            try {
                let url = `${API_ENDPOINT}/staffs?page=${page}`;

                if (search) {
                    url += `&search=${search}`;
                }

                if (sortBy) {
                    url += `&sort=${sortBy}`;
                }

                if (department) {
                    url += `&department=${department}`
                }

                const res = await axios.get(url);
                setStaffsData(res.data.items);
                setResult(res.data.result);
                setTotal(res.data.total);
            } catch (error) {
                // Handle error
                console.error("Error fetching staffs:", error);
            }
        };

        getStaffs();
    }, [callback, page, search, sortBy, department]);

    return {
        staffsData: [staffsData, setStaffsData],
        callback: [callback, setCallback],
        search: [search, setSearch],
        page: [page, setPage],
        result: [result, setResult],
        total: [total, setTotal],
        sortBy: [sortBy, setSortBy],
        department: [department, setDepartment]
    };
}
