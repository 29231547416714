import React, { useState } from 'react';
import CSidebar from '../../components/Nav/CSidebar';
import Header from '../../components/Header/Header';
import { NavLink, Outlet } from 'react-router-dom';

function Staffs() {
    const [navOpen, setNavOpen] = useState(false);

    const handleToggleNav = () => {
        setNavOpen(!navOpen);
    };

    return (
        <div className="d-flex profile">
            <div>
                <CSidebar />
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                <Header />

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                        <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                            <h5>Staffs</h5>
                        </div>

                        <div className='py-2'>
                            <div className="nav-container">
                                <NavLink to="/staffs" className={({ isActive, isPending, isTransitioning }) =>
                                    [
                                        isPending ? "pending" : "",
                                        isActive ? "active" : "",
                                        isTransitioning ? "transitioning" : "",
                                    ].join(" ")
                                }>
                                    <button className="btn-b in-nav">
                                        <span className="me-2 fw-bold">  </span>
                                        Staff List
                                    </button>
                                </NavLink>

                                <button className="hamburger" onClick={handleToggleNav}>
                                    &#9776;
                                </button>
                            </div>

                            <ul className={`mobile-page-nav py-1 ${navOpen ? 'open' : ''}`}>
                                <li>
                                    <NavLink to="/staffs/add-staff" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Add Staff
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/staffs/departments" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Manage Departments
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/staffs/designations" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Manage Designations/Roles
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/staffs/permissions" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Manage Permissions
                                        </button>
                                    </NavLink>
                                </li>
                            </ul>

                            <ul className={`page-nav py-1`}>
                                <li>
                                    <NavLink to="/staffs" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Staff List
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/staffs/add-staff" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Add Staff
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/staffs/departments" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Manage Departments
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/staffs/designations" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Manage Designations/Roles
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/staffs/permissions" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Manage Permissions
                                        </button>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="py-3">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Staffs;