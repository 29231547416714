import React from 'react';

function Investigators(props) {
    return (
        <div>
            <div>
                <div className="bg-white p-3 mb-3">
                    <div className="row">
                        <div className="col-lg-3 pi-sec-b">
                            <h6>PI #1</h6>
                            <p>John Doe</p>
                        </div>

                        <div className="col-lg-3 pi-sec-b">
                            <h6>Credentials</h6>
                            <p>MD</p>
                        </div>

                        <div className="col-lg-3 pi-sec-b">
                            <h6>Specialty/Specialities</h6>
                            <p>MD</p>
                        </div>

                        <div className="col-lg-3 pi-sec-b">
                            <h6>Board Certified</h6>
                            <p>MD</p>
                        </div>
                    </div>
                    <div className="border-top pt-3 d-flex">
                        <button className="btn-b me-2">Edit</button>
                        <button className="btn-d">Delete</button>
                    </div>
                </div>

                <div className="bg-white p-3 mb-3">
                    <div className="row">
                        <div className="col-lg-3 si-sec-b">
                            <h6>PI #2</h6>
                            <p>John Doe</p>
                        </div>

                        <div className="col-lg-3 si-sec-b">
                            <h6>Credentials</h6>
                            <p>MD</p>
                        </div>

                        <div className="col-lg-3 si-sec-b">
                            <h6>Specialty/Specialities</h6>
                            <p>MD</p>
                        </div>

                        <div className="col-lg-3 si-sec-b">
                            <h6>Board Certified</h6>
                            <p>MD</p>
                        </div>
                    </div>
                    <div className="border-top pt-3 d-flex">
                        <button className="btn-b me-2">Edit</button>
                        <button className="btn-d">Delete</button>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Investigators;