import React from 'react';

function Documents(props) {
    return (
        <div>
            Docs
        </div>
    );
}

export default Documents;