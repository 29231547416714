import React from 'react';

function AboutInfo({ data }) {
    return (
        <div>
            <div className="bg-white p-3">
                <h6>Contact Information</h6>
                <div className="row">
                    <div className="col-lg-6 si-sec-b">
                        <h6>Email</h6>
                        <p> {data.email ? `${data.email}`: "nil"} </p>
                    </div>
                    <div className="col-lg-6 si-sec-b">
                        <h6>Phone Number</h6>
                        <p>Phase 1 Phase 2 Phase 3</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 si-sec-b">
                        <h6>Street Address</h6>
                        <p> {data.street_address ? `${data.street_address}`: "nil"} </p>
                    </div>
                    <div className="col-lg-6 si-sec-b">
                        <h6>Apt, Suite, etc</h6>
                        <p> {data.apt_suite_ect ? `${data.apt_suite_ect}`: "nil"} </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 si-sec-b">
                        <h6>City</h6>
                        <p> {data.city ? `${data.city}`: "nil"} </p>
                    </div>
                    <div className="col-lg-6 si-sec-b">
                        <h6>State/Province</h6>
                        <p> {data.state_province ? `${data.state_province}`: "nil"} </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 si-sec-b">
                        <h6>Country/Region</h6>
                        <p> {data.country ? `${data.country}`: "nil"} </p>
                    </div>
                    <div className="col-lg-6 si-sec-b">
                        <h6>Zip/Post code</h6>
                        <p> {data.zip_postcode ? `${data.zip_postcode}`: "nil"} </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutInfo;