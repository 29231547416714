import React, { useState } from 'react';
import CSidebar from '../../components/Nav/CSidebar';
import Header from '../../components/Header/Header';
import { NavLink, Outlet } from 'react-router-dom';
import { FaListCheck } from "react-icons/fa6";
import { FaRegPenToSquare } from "react-icons/fa6";
import { TbChartInfographic } from "react-icons/tb";

function Studies() {
    const [navOpen, setNavOpen] = useState(false);

    const handleToggleNav = () => {
        setNavOpen(!navOpen);
    };

    return (
        <div className="d-flex profile">
            <div>
                <CSidebar />
            </div>
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                <Header />

                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 24px)", padding: "20px 5%", overflowY: "scroll" }}>
                        <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                            <h5>Studies</h5>
                        </div>

                        <div className='py-2'>
                            <div className="nav-container">
                                <NavLink to="/studies" className={({ isActive, isPending, isTransitioning }) =>
                                    [
                                        isPending ? "pending" : "",
                                        isActive ? "active" : "",
                                        isTransitioning ? "transitioning" : "",
                                    ].join(" ")
                                }>
                                    <button className="btn-b in-nav">
                                        <span className="me-2"> <FaListCheck /> </span>
                                        List of Studies
                                    </button>
                                </NavLink>

                                <button className="hamburger" onClick={handleToggleNav}>
                                    &#9776;
                                </button>
                            </div>

                            <ul className={`mobile-page-nav py-1 ${navOpen ? 'open' : ''}`}>
                                <li>
                                    <NavLink to="/studies/new-study" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2"> <FaRegPenToSquare /> </span>
                                            Create Study
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/studies/study-information" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2"> <TbChartInfographic /> </span>
                                            Study Information
                                        </button>
                                    </NavLink>
                                </li>
                            </ul>

                            <ul className={`page-nav py-1`}>
                                <li>
                                    <NavLink to="/studies" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2"> <FaListCheck /> </span>
                                            List of Studies
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/studies/new-study" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2"> <FaRegPenToSquare /> </span>
                                            Create Study
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/studies/study-information" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2"> <TbChartInfographic /> </span>
                                            Study Information
                                        </button>
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/studies/new-study" className={({ isActive, isPending, isTransitioning }) =>
                                        [
                                            isPending ? "pending" : "",
                                            isActive ? "active" : "",
                                            isTransitioning ? "transitioning" : "",
                                        ].join(" ")
                                    }>
                                        <button className="btn-b in-nav">
                                            <span className="me-2 fw-bold">  </span>
                                            Timeline/Milestones
                                        </button>
                                    </NavLink>
                                </li> */}
                            </ul>
                        </div>

                        <div className="py-3">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Studies;