import React, { useContext } from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';

import Logo from '../../assets/img/phoenixicon.png';
import axios from 'axios';
import { GlobalState } from '../../GlobalState';

const CSidebar = () => {
    const state = useContext(GlobalState);
    const [ict] = state.ict;
    const [mgmt] = state.mgmt;
    const [clientRel] = state.clientRel;
    const [studyMgmt] = state.studyMgmt;
    // eslint-disable-next-line no-unused-vars
    const [regulatoryDept] = state.regulatoryDept;
    // eslint-disable-next-line no-unused-vars
    const [contractsDept] = state.contractsDept;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const logoutUser = async () => {
        try {
            await axios.post(`${API_ENDPOINT}/staffs/logout`);
            localStorage.clear();
            alert("User logged out");
            window.location.href = "/login";
        } catch (error) {
            alert(error.response.msg);
        }
    };

    const IctNav = () => (
        <CDBSidebarMenu>
            <NavLink to="/" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/studies" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="book">Studies</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/sites" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="sitemap">Sites</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/budgets" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="coins">Budgets</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/staffs" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="id-card">Staffs</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/user-management" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="user-check">User Management</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/my-account" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="id-badge">My account</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="#" activeClassName="activeClicked" onClick={logoutUser}>
                <CDBSidebarMenuItem icon="location-arrow">Logout</CDBSidebarMenuItem>
            </NavLink>
        </CDBSidebarMenu>
    );

    const MgmtNav = () => (
        <CDBSidebarMenu>
            <NavLink to="/" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/studies" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="book">Studies</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/sites" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="sitemap">Sites</CDBSidebarMenuItem>
            </NavLink>
            {/* <NavLink to="/budgets" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="coins">Budgets</CDBSidebarMenuItem>
            </NavLink> */}
            <NavLink to="/staffs" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="id-card">Staffs</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/my-account" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="id-badge">My account</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="#" activeClassName="activeClicked" onClick={logoutUser}>
                <CDBSidebarMenuItem icon="location-arrow">Logout</CDBSidebarMenuItem>
            </NavLink>
        </CDBSidebarMenu>
    );


    const StudyMgmtNav = () => (
        <CDBSidebarMenu>
            <NavLink to="/" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/studies" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="book">Studies</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/my-account" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="id-badge">My account</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="#" activeClassName="activeClicked" onClick={logoutUser}>
                <CDBSidebarMenuItem icon="location-arrow">Logout</CDBSidebarMenuItem>
            </NavLink>
        </CDBSidebarMenu>
    );

    const ClientRelNav = () => (
        <CDBSidebarMenu>
            <NavLink to="/" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/studies" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="book">Studies</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/sites" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="sitemap">Sites</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/my-account" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="id-badge">My account</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="#" activeClassName="activeClicked" onClick={logoutUser}>
                <CDBSidebarMenuItem icon="location-arrow">Logout</CDBSidebarMenuItem>
            </NavLink>
        </CDBSidebarMenu>
    );

    return (
        <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
            <CDBSidebar textColor="#fff" backgroundColor="#2980AB" toggled breakpoint={720}>
                <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
                    <NavLink to="/" className="text-decoration-none" style={{ color: 'inherit' }}>
                        <div className="container" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={Logo} alt="" style={{ width: '30px' }} />
                            <h6 className="ms-1">PHOENIX TMG™</h6>
                        </div>
                    </NavLink>
                </CDBSidebarHeader>
                <CDBSidebarContent className="sidebar-content">
                    {ict && <IctNav />}
                    {mgmt && <MgmtNav />}
                    {clientRel && <ClientRelNav />}
                    {studyMgmt && <StudyMgmtNav />}
                    {/* {regulatoryDept && <StudyMgmtNav />}
                    {contractsDept && <StudyMgmtNav />} */}

                    {/* deleted */}
                    {/* {dataMgmt ? <DataMgtNav /> : null}
                    {clientRel ? <ClientRelNav /> : null}
                    {ict ? <IctNav /> : null} */}
                </CDBSidebarContent>
                <CDBSidebarFooter style={{ textAlign: 'center' }}>
                    <div style={{ padding: '20px 5px' }}>Sidebar Footer</div>
                </CDBSidebarFooter>
            </CDBSidebar>
        </div>
    );
};

export default CSidebar;
