import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';

const initialState = {
    name: "",
    permissions: []
}

function RolesForm(props) {
    const [details, setDetails] = useState(initialState)
    const { name } = details

    const state = useContext(GlobalState);
    const [permissionsData] = state.permissionsApi.permissionsData;
    const [displayedOptions, setDisplayedOptions] = useState(permissionsData);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

   const [, setAlert] = state.alert;

    const handleChange = e => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value })
    }

    const handleCheckboxChange = (option) => {
        const updatedCheckboxes = [...selectedCheckboxes];
        const index = updatedCheckboxes.indexOf(option.name);

        if (index === -1) {
            updatedCheckboxes.push(option.name);
            const updatedOptions = displayedOptions.filter(
                (item) => item.name !== option.name
            );
            setDisplayedOptions(updatedOptions);
        } else {
            updatedCheckboxes.splice(index, 1);
            setDisplayedOptions([...displayedOptions, option]);
        }

        setSelectedCheckboxes(updatedCheckboxes);
    };

    const handleDeselectOption = (selectedOption) => {
        const updatedCheckboxes = selectedCheckboxes.filter(
            (option) => option !== selectedOption
        );
        const updatedOptions = [
            ...displayedOptions,
            permissionsData.find((option) => option.name === selectedOption),
        ];
        setSelectedCheckboxes(updatedCheckboxes);
        setDisplayedOptions(updatedOptions);
    };

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log({ ...details, selectedCheckboxes })

        if (!name || !selectedCheckboxes) {
           setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }

        try {
            const res = await axios.post(`${API_ENDPOINT}/roles`, { ...details, permissions: selectedCheckboxes },
                { withCredentials: true, credentials: 'include' }
            )
             setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setDetails(initialState)
            setSelectedCheckboxes([])
        } catch (error) {
            // console.log(error)
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    return (
        <div className='container border py-3'>
            <div className='row'>
                <div className='col-lg-3'>
                    <h6>Create a new role</h6>

                    <div>
                        <p>Instructions:</p>

                        <ul className='list-group list-group-numbered list-group-flush'>
                            <li className='list-group-item bg-none'>Enter the role title</li>
                            <li className='list-group-item bg-none'>Select the list of permissions accorded the role accordingly from the list of available permissions</li>
                        </ul>
                    </div>
                </div>
                <div className='col-lg-9'>
                    <div className='bg-white p-3'>
                        <form onSubmit={handleSubmit}>

                            <div className='row mb-3'>
                                <div className='col-lg-12'>
                                    <label htmlFor='name' className='form-label'>Role Title</label>
                                    <input type="text" className='form-control form-control-md' id="name" name='name'
                                        value={name} onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className='row mb-3'>
                                <div className='col-lg-12 p-3'>
                                    <label htmlFor='permissions' className='form-label mb-3'>permissions</label>
                                    <div className='row mb-4 bg-light p-3 rounded-2'>
                                        {selectedCheckboxes.map((selectedOption) => (
                                            <div className='col-lg-4 mb-3' key={selectedOption}>
                                                {selectedOption}{' '}
                                                <button className='btn-c sm-c' onClick={() => handleDeselectOption(selectedOption)}>
                                                    Deselect
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='row p-2'>
                                        {displayedOptions.map((option) => (
                                            <div className='col-lg-3 form-check mb-3'>
                                                <div key={option._id}>
                                                    <input
                                                        type="checkbox"
                                                        className='form-check-input'
                                                        id={option._id}
                                                        value={option.name}
                                                        checked={selectedCheckboxes.includes(option.name)}
                                                        onChange={() => handleCheckboxChange(option)}
                                                    />
                                                    <label className='form-check-label' htmlFor={option._id}>{option.name}</label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex'>
                                <Link to="/user-management/roles"><button className='btn-b me-2'>Cancel</button></Link>
                                <button type="submit" className='btn-c'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RolesForm;