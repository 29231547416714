import React, { useContext } from 'react';
import { GlobalState } from '../../GlobalState';
import { Link } from 'react-router-dom';

function StaffLists(props) {
    const state = useContext(GlobalState);
    const [staffsData] = state.staffsApi.staffsData;
    const [search, setSearch] = state.staffsApi.search;
    const [page, setPage] = state.staffsApi.page;
    // const [sortBy, setSortBy] = state.staffsApi.sortBy;
    const [result] = state.staffsApi.result;
    const [total] = state.staffsApi.total;
    const [department, setDepartment] = state.staffsApi.department;

    const [departmentsData] = state.departmentsApi.departmentsData;

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1); // Reset page number when searching
    };

    // const handleSortChange = (e) => {
    //     setSortBy(e.target.value);
    // };

    const handleDepartmentChange = (e) => {
        setDepartment(e.target.value);
        setPage(1); // Reset page number when department filter changes
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h6>Staff Lists</h6>
                        </div>
                        {/*  */}
                    </div>
                </div>
                <div className='card-body'>
                    <div className='row my-2'>
                        {/* Search Table */}
                        <div className='col-lg-6'>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control form-control-sm" placeholder="Search staffs...." ariaLabel="Recipient's username" ariaDescribedby="button-addon2"
                                    value={search}
                                    onChange={handleSearchChange}
                                />
                                <button className="btn btn-outline-secondary" type="button" id="button-addon2">search</button>
                            </div>
                        </div>
                        {/* Sort Table */}
                        <div className='col-lg-6'>
                            <select value={department} onChange={handleDepartmentChange} className="form-select" ariaLabel=" sort table according to department" >
                                <option value="">Sort by Department</option>
                                {
                                    departmentsData.map((e) =>
                                        <option key={e._id} value={e._id}> {e.name} </option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Staff Id</th>
                                    <th>Email</th>
                                    <th>Department</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    staffsData.length === 0 ?
                                        <>
                                            <div className='text-center py-3 w-100'>No information available yet</div>
                                        </>
                                        : <>
                                            {
                                                staffsData.map((e, i) =>
                                                    <tr key={e._id}>
                                                        <td> {i + 1} </td>
                                                        <td> {e.firstname} {e.lastname} </td>
                                                        <td> {e.staff_ID} </td>
                                                        <td> {e.email} </td>
                                                        <td> {e.department.name} </td>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <Link to={`/staffs/edit-staff/${e._id}`}><button className='btn-b me-2'>Edit</button></Link>
                                                                <Link to=""><button className='btn-d me-2'>Delete</button></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                }
                            </tbody>

                        </table>

                        {
                            result < page * 20 ? ""
                                : <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <btn class="page-link" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>Previous</btn>
                                        </li>
                                        <li className="page-item">
                                            <btn class="page-link" >1</btn>
                                        </li>
                                        <li className="page-item">
                                            <btn class="page-link" onClick={() => handlePageChange(page + 1)} disabled={page === Math.ceil(total / result)}>Next</btn>
                                        </li>
                                    </ul>
                                </nav>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default StaffLists;