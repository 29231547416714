import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalState } from '../../GlobalState';

function Permissions(props) {
    const state = useContext(GlobalState);
    const [permissionsData] = state.permissionsApi.permissionsData


    return (
        <>
            <div className="p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Permissions Management</h5>

                    <Link to="/staffs/permissions/new-permission"><button className="btn-d"> Add Permission </button></Link>
                </div>

                <div className='card'>
                <div className='card-header'>
                    <h6>List of permissions</h6>
                </div>

                <div className='card-body'>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Designation/Role</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    permissionsData.length === 0 ?
                                        <>
                                            <div className='text-center py-3 w-100'>No information available yet</div>
                                        </>
                                        : <>
                                            {
                                                permissionsData.map((e, i) =>
                                                    <tr key={e._id}>
                                                        <td> {i + 1} </td>
                                                        <td> {e.name} </td>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <button className='btn-b me-2'>Edit</button>
                                                                <button className='btn-d me-2'>Delete</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}

export default Permissions;