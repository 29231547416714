import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalState } from '../../GlobalState';

function SiteLists() {
    const state = useContext(GlobalState);
    const [sitesData] = state.sitesApi.sitesData;


    return (
        <>
            <div className="card">
                <div className='card-header'>
                    <h6>List of Sites</h6>
                </div>
                <div className='card-body'>
                    {
                        sitesData.length === 0 ?
                            <>
                                <h6>There are no registered sites at the moment</h6>
                            </>
                            : <>
                                <DataTable data={sitesData} />
                            </>
                    }
                </div>
            </div>
        </>
    );
}

export default SiteLists;


function DataTable({ data }) {
    return (
        <>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <th>#</th>
                        <th>Site name</th>
                        <th>Type of site</th>
                        <th>Phase Capability</th>
                        <th>City</th>
                        <th>Phone Number</th>
                        <th>Year Established</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        {
                            data.map((e, i) =>
                                <tr key={e._id}>
                                    <td> {i + 1} </td>
                                    <td> {e.site_name} </td>
                                    <td> {e.type_of_site} </td>
                                    <td>
                                        {e.is_phase_one_site === true ? "Phase 1 " : ""}
                                        {e.is_phase_two_site === true ? "Phase 2 " : ""}
                                        {e.is_phase_three_site === true ? "Phase 3 " : ""}
                                        {e.is_phase_four_site === true ? "Phase 4 " : ""}
                                    </td>
                                    <td> {e.city} </td>
                                    <td> {e.site_phone_number} </td>
                                    <td> {e.year_established} </td>
                                    <td>
                                        <div className='d-flex'>
                                            <Link to={`/sites/${e._id}`}>
                                                <button className='btn-b me-2'>View</button>
                                            </Link>
                                            <button className='btn-c'>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}