import React from 'react';

function Loader(props) {
    return (
        <div className='w-100' style={{ height: '100vh', position: 'fixed', zIndex: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#0008' }}>
            <div className="spinner spinner--steps icon-spinner" aria-hidden="true"></div>
        </div>
    );
}

export default Loader;