import React, { useContext, useState } from 'react';
import { GlobalState } from '../../GlobalState';
import Alert from '../../utils/Alert';
import axios from 'axios';

const initialState = {
    name: ""
}

function Designations(props) {
    const [details, setDetails] = useState(initialState)
    const { name } = details;

    const state = useContext(GlobalState);
    const [rolesData] = state.rolesApi.rolesData;
    const [callback, setCallback] = state.rolesApi.callback;
    const [failureAlert, setFailureAlert] = state.failureAlert;
    const [successAlert, setSuccessAlert] = state.successAlert;
    const [failureMsg, setFailureMsg] = state.failureMsg;
    const [successMsg, setSuccessMsg] = state.successMsg;

    const handleChange = e => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value })
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const submitForm = async (e) => {
        e.preventDefault();

        try {
            if (!details) {
                setFailureMsg('input field cannot be empty.');
                setFailureAlert(true);
                return;
            }

            const response = await axios.post(`${API_ENDPOINT}/roles`, { ...details });

            if (response.status === 200) {
                setSuccessMsg(response.data.msg);
                setSuccessAlert(true);
                setCallback(!callback);
                setDetails(initialState);
            } else {
                setFailureMsg(response.data.msg);
                setFailureAlert(true);
            }


        } catch (error) {
            console.error('Error submitting data:', error);
            setFailureMsg(error.response.data.msg);
            setFailureAlert(true);
        }
    }


    return (
        <div>
            <div className='d-flex justify-content-between mb-3'>
                <h6>Manage Designations</h6>
                <button type="button" class="btn-b" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Add designation/role</button>
            </div>

            <div className='card'>
                <div className='card-header'>
                    <h6>List of staff designations/roles</h6>
                </div>

                <div className='card-body'>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Designation/Role</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rolesData.length === 0 ?
                                        <>
                                            <div className='text-center py-3 w-100'>No information available yet</div>
                                        </>
                                        : <>
                                            {
                                                rolesData.map((e, i) =>
                                                    <tr key={e._id}>
                                                        <td> {i + 1} </td>
                                                        <td> {e.name} </td>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <button className='btn-b me-2'>Edit</button>
                                                                <button className='btn-d me-2'>Delete</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {/* <!-- Modal --> */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Add new designation/role</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={submitForm}>
                            <div className="modal-body">
                                <div className='my-2'>
                                    {
                                        failureAlert && <Alert alertClass={"alert-danger"} alertInfo={failureMsg} closeAction={(failureAlert) => setFailureAlert(!failureAlert)} />
                                    }
                                    {
                                        successAlert && <Alert alertClass={"alert-success"} alertInfo={successMsg} closeAction={(successAlert) => setSuccessAlert(!successAlert)} />
                                    }
                                </div>
                                <div className='py-3'>
                                    <label className='form-label'>Name of designation</label>
                                    <input type="text" className='form-control form-control-md' id='name' name='name'
                                        value={name} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn-d" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn-a">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Designations;