import React, { useContext, useState } from 'react';
import { GlobalState } from '../../GlobalState';
import Nodata from '../../utils/Nodata';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Users(props) {
    const state = useContext(GlobalState);
    const [usersData] = state.usersApi.usersData
    const [callback, setCallback] = state.usersApi.callback;
    const [, setAlert] = state.alert;
    const [search, setSearch] = state.usersApi.search;
    const [page, setPage] = state.usersApi.page;
    // const [sortBy, setSortBy] = state.staffsApi.sortBy;
    const [result] = state.usersApi.result;
    const [total] = state.usersApi.total;

    const [selectedUserId, setSelectedUserId] = useState(null);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const deleteStudy = async (id) => {
        try {
            const res = await axios.delete(`${API_ENDPOINT}/users/${id}`);
            setCallback(!callback);
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: "",
            });
            setTimeout(() => setAlert({ failure: false, success: false }), 2000);
        } catch (error) {
            console.error("Error deleting study:", error);
            setAlert({
                failure: true,
                success: false,
                failureMsg: "Error deleting study",
                successMsg: "",
            });
        }
    };

    const handleDeleteConfirmation = () => {
        // Check if a study ID is selected before proceeding with deletion
        if (selectedUserId) {
            deleteStudy(selectedUserId);
            // Reset the selectedStudyId after deletion
            setSelectedUserId(null);
            // Close the modal
            document.getElementById("staticBackdrop").click(); // Programmatically close the modal
        }
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1); // Reset page number when searching
    };

    // const handleSortChange = (e) => {
    //     setSortBy(e.target.value);
    // };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <h6>List of Users</h6>
                </div>


                {
                    usersData.length === 0 ?
                        <>
                            <Nodata />
                        </>
                        : <>
                            <div className='card-body'>
                                <div className='row my-2'>
                                    {/* Search Table */}
                                    <div className='col-lg-6'>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control form-control-sm" placeholder="Search users...." ariaLabel="Recipient's username" ariaDescribedby="button-addon2"
                                                value={search}
                                                onChange={handleSearchChange}
                                            />
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2">search</button>
                                        </div>
                                    </div>
                                    {/* Sort Table */}
                                    <div className='col-lg-6'>
                                        <select
                                            // value={department} onChange={handleDepartmentChange} 
                                            className="form-select" ariaLabel=" sort table according to department" >
                                            <option value="">Sort by Status</option>
                                            {/* {
                        departmentsData.map((e) =>
                          <option key={e._id} value={e._id}> {e.name} </option>
                        )
                      } */}
                                        </select>
                                    </div>
                                </div>
                                <div className='table-responsive'>
                                    <table className='table table-sm'>
                                        <thead>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Ref No</th>
                                            {/* <th>Status</th> */}
                                            <th>State</th>
                                            <th>Start Date</th>
                                            <th>Submission Date</th>
                                            <th>Staff in-charge</th>
                                            <th>Action</th>
                                        </thead>

                                        <tbody>
                                            {
                                                usersData.map((e, i) =>
                                                    <tr key={e._id}>
                                                        <td> {i + 1} </td>
                                                        {/* <td> {e.study_title} </td>
                                                        <td> {e.study_id} </td>
                                                        <td> {e.study_state} </td>
                                                        <td> {e.start_date} </td>
                                                        <td> {e.deadline_for_submission} </td>
                                                        <td> {e.staff.firstname} {e.staff.lastname} </td> */}
                                                        <td>
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-info btn-sm btn-icon rounded-pill dropdown-toggle hide-arrow waves-effect waves-light" data-bs-toggle="dropdown" aria-expanded="false">

                                                                </button>
                                                                <ul className="dropdown-menu dropdown-menu-end">
                                                                    <li>
                                                                        <Link
                                                                            to="#"
                                                                        // to={`/studies/edit-study/${e._id}`}
                                                                        >
                                                                            <span className="dropdown-item">Edit</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li><span className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                                        onClick={() => setSelectedUserId(e._id)}
                                                                    >Delete</span></li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>

                                    {
                                        result < page * 20 ? ""
                                            : <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <btn class="page-link" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>Previous</btn>
                                                    </li>
                                                    <li className="page-item">
                                                        <btn class="page-link" >1</btn>
                                                    </li>
                                                    <li className="page-item">
                                                        <btn class="page-link" onClick={() => handlePageChange(page + 1)} disabled={page === Math.ceil(total / result)}>Next</btn>
                                                    </li>
                                                </ul>
                                            </nav>
                                    }

                                </div>
                            </div>
                        </>
                }
            </div >


            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Confirm your action</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <b>Are you sure you want to delete this item?</b>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-danger" onClick={handleDeleteConfirmation} data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users;